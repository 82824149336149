<template>
  <div class="icon-item-info">
    <div class="swiper-box">
      <swiper ref="mySwiper" :options="swiperOption" @slideChange="slideChange" v-if="swiperImgList.length > 0">
        <swiper-slide class="swiper-slide" v-for="(item,index) in swiperImgList" :key="index">
          <img :src="item.picUrl" alt="" />
        </swiper-slide>
      </swiper>
      <div class="custom-pagination" v-if="swiperImgList.length > 1">
        <div class="custom" v-for="(item,index) in swiperImgList" :key="index" :class="currentSwiper == index ? 'custom-active' :''"></div>
      </div>
    </div>
    <div class="tabs-list">
      <template v-for="(item,index) in tabsList">
        <div class="tab-item" :key="index" :class="currentTab == index ? 'tab-item-sel' :''" @click="tabClick(index)" v-if="index !== 2 && index !== 3">{{$store.state.language ? item.lable : item.lableE}}</div>
        <!--  品牌文创 品牌形象片 可能没有 -->
        <div class="tab-item" :key="index" :class="currentTab == index ? 'tab-item-sel' :''" @click="tabClick(index)" v-if="index == 2 && info.culturalCreationList && info.culturalCreationList.length > 0">{{$store.state.language ? item.lable : item.lableE}}</div>
        <div class="tab-item" :key="index" :class="currentTab == index ? 'tab-item-sel' :''" @click="tabClick(index)" v-if="index == 3 && info.propaganda">{{$store.state.language ? item.lable : item.lableE}}</div>
      </template>
    </div>
    <div class="tab-title" ref="title1">
      <c-title :width="16" :type="2">
        <div class="text">{{$t('brandimage')}}</div>
      </c-title>
    </div>
    <div class="brand-image">
      <div class="direction-reveal">
        <div class="direction-reveal__card image-box">
          <img :src="info.brandImage" alt="" class="image" style="">
          <template v-if="info.imageExplain">
            <div class="direction-reveal__overlay direction-reveal__anim--enter image-explain">
              <div v-html="$store.state.language ? info.imageExplain : info.imageExplainE"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="tab-title" ref="title2">
      <c-title :width="16" :type="2">
        <div class="text">{{$t('packing')}}</div>
      </c-title>
    </div>
    <div>
      <img :src="info.brandPack" class="packing" alt="">
    </div>
    <template v-if="info.culturalCreationList && info.culturalCreationList.length > 0">
      <div class="tab-title" ref="title3">
        <c-title :width="16" :type="2">
          <div class="text">{{$t('cultural')}}</div>
        </c-title>
      </div>
      <div class="cultural">
        <swiper :options="swiperOption2">
          <swiper-slide class="swiper-slide" v-for="(item,index) in info.culturalCreationList" :key="index">
            <img :src="item.picUrl" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </template>
    <template v-if="getVideoUrl()">
      <div class="tab-title" ref="title4">
        <c-title :width="16" :type="2">
          <div class="text">{{$t('brandVideo')}}</div>
        </c-title>
      </div>
      <div class="video-box">
        <video :src="getVideoUrl()" controls="controls" width="100%" @play="onPlayerPlay()" controlsList="nodownload" oncontextmenu="return(false);">
          您的浏览器不支持 video 标签。
        </video>
      </div>
    </template>
    <div class="link">
      <div @click="linkClick()" class="text">{{$t('relevantText')}}</div>
    </div>
    <sidebar :qrCodeUrl="info.wechatQrCode"></sidebar>
  </div>
</template>

<script>
import cTitle from '@/components/cTitle/cTitle.vue'

import { houseItemInfo, houseItemInfoByBrand } from '@/api/house'
import DirectionReveal from 'direction-reveal'
import sidebar from '@/components/sidebar/sidebar'

export default {
  components: {
    cTitle,
    sidebar
  },
  data() {
    return {
      // tabsList: ['品牌形象', '品牌包装', '品牌文创', '品牌形象片'],
      tabsList: [
        { lable: '品牌形象', lableE: 'Brand Image' },
        { lable: '品牌包装', lableE: 'Packaging System' },
        { lable: '品牌文创', lableE: 'Cultural and Creative Products' },
        { lable: '品牌形象片', lableE: 'Brand Image Video' }
      ],
      currentSwiper: 0,
      currentTab: 0,
      swiperOption: {
        //显示分页
        // pagination: {
        //   el: '.swiper-pagination',
        //   bulletClass: 'customs',
        //   bulletActiveClass: 'customs-active',
        //   renderBullet(index, className) {
        //     return '<div class="' + className + '"></div>'
        //   },
        // },
        //自动轮播
        autoplay: {
          delay: 3000,
          disableOnInteraction: false
        },
        loop: true
      },
      swiperOption2: {
        slidesPerView: 3,
        loop: true,
        mousewheel: true
      },
      info: {},
      swiperImgList: []
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  watch: {
    swiperImgList(val) {
      if (val.length === 1) {
        this.swiperOption.loop = false
      }
    }
  },
  created() {
    if (this.$route.query.brandId) {
      this.getInfo2()
    } else {
      this.getInfo()
    }
  },
  methods: {
    // 暂停背景音乐
    onPlayerPlay() {
      this.$store.commit('pauseMusic')
    },
    slideChange() {
      this.currentSwiper = this.swiper.realIndex
    },
    // 获取详情
    getInfo() {
      houseItemInfo({ warehouseId: this.$route.query.warehouseId }).then(
        (res) => {
          if (res.code === 10000) {
            this.extractData(res.data)
          }
        }
      )
    },
    getInfo2() {
      houseItemInfoByBrand({ brandId: this.$route.query.brandId }).then(
        (res) => {
          if (res.code === 10000) {
            this.extractData(res.data)
          }
        }
      )
    },
    extractData(data) {
      this.info = data
      this.swiperImgList = this.$store.state.language
        ? this.info.tabBrandWebPicList
        : this.info.tabBrandWebPicEnglishList
      this.$store.commit('addLinks', data.tabBrandWebsiteLinkList)
      this.$route.meta.title = data.brandName
      this.$route.meta.titleE = data.brandNameE
      this.initReveal()
    },
    getVideoUrl() {
      if (this.$store.state.language) {
        return this.info.propaganda
      } else {
        return this.info.propagandaE
          ? this.info.propagandaE
          : this.info.propaganda
      }
    },
    // 初始化组件
    initReveal() {
      setTimeout(() => {
        const directionRevealDefault = DirectionReveal({
          selector: '.direction-reveal',
          itemSelector: '.direction-reveal__card',
          animationName: 'roll-out',
          animationPostfixEnter: 'enter',
          animationPostfixLeave: 'leave',
          enableTouch: true,
          touchThreshold: 250
        })
      })
    },
    tabClick(index) {
      this.currentTab = index
      document.documentElement.scrollTop =
        this.$refs['title' + (index + 1)].offsetTop - 70
    },
    linkClick() {
      let query = this.$route.query
      this.$router.push({
        path: '/brand/brandItem',
        query: {
          brandId: this.info.brandId,
          name: query.name,
          nameE: query.nameE
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import 'node_modules/direction-reveal/src/styles/direction-reveal.scss';

.icon-item-info {
  width: 1140px;
  margin: 21px auto 0;
  .swiper-box {
    width: 100%;
    position: relative;
    .swiper-container {
      width: 100%;
      height: 100%;
      .swiper-pagination {
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 20px;
        .customs {
          width: 32px;
          height: 16px;
          margin: 0 6px;
          background: url('../../static/img/home/customs.png') no-repeat center;
          background-size: 100% 100%;
        }
        .customs-active {
          background: url('../../static/img/home/customs_active.png') no-repeat
            center;
          background-size: 100% 100%;
        }
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .custom-pagination {
      width: 100%;
      height: 16px;
      margin: 20px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      .custom {
        width: 32px;
        height: 16px;
        margin: 0 6px;
        background: url('../../static/img/home/customs.png') no-repeat center;
        background-size: 100% 100%;
      }
      .custom-active {
        background: url('../../static/img/home/customs_active.png') no-repeat
          center;
        background-size: 100% 100%;
      }
    }
  }
  .tabs-list {
    display: flex;
    justify-content: center;
    margin: 40px 0 0 0;
    .tab-item {
      font-size: 16px;
      line-height: 16px;
      padding: 0 0 0 26px;
      background: url('../../static/img/storeHouse/tab_icon.png') no-repeat left
        center;
      background-size: 20px 16px;
      margin: 0 16px;
      cursor: pointer;
    }
    .tab-item-sel {
      background: url('../../static/img/storeHouse/tab_icon_sel.png') no-repeat
        left center;
      background-size: 20px 16px;
    }
  }
  .tab-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px 0 20px 0;
    .text {
      color: #595757;
      font-size: 30px;
      line-height: 30px;
    }
  }
  .brand-image {
    .image-box {
      width: 100%;
    }
    .image-explain {
      display: flex;
      padding: 0 105px;
      align-items: center;
      justify-content: center;
      background: rgba($color: #3e3a39, $alpha: 0.83);
    }
  }
  .image,
  .packing {
    width: 100%;
    height: auto;
  }
  .cultural {
    .swiper-container {
      img {
        width: 371px;
        height: 250px;
      }
    }
  }
  .video-box {
    // display: flex;
    min-height: 300px;
    video {
      min-height: 300px;
    }
  }

  .link {
    font-size: 17px;
    line-height: 17px;
    color: #595757;
    display: flex;
    justify-content: flex-end;
    margin: 20px 0 40px 0;
    .text {
      background: url('../../static/img/layout/link_icon2.png') no-repeat left
        center;
      background-size: 28px 9px;
      padding: 0 0 0 35px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>