<template>
  <div class="sidebar">
    <img src="../../static/img/sidebar/icon1.png" alt="" class="icon" @click="backTop ()">
    <img src="../../static/img/sidebar/icon2.png" alt="" class="icon" @mouseover="hover = true" @mouseleave="hover = false" v-if="qrCodeUrl">
    <div class="qr-code" v-if="hover">
      <img :src="qrCodeUrl" class="qr-code-img">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    qrCodeUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      hover: false
    }
  },
  methods: {
    backTop() {
      document.body.scrollTop = 0
      document.documentElement.scrollTop = 0
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 50%;
  bottom: 20%;
  margin: 0 0 0 578px;
  .icon {
    width: 40px;
    height: 40px;
    margin: 0 0 4px 0;
    cursor: pointer;
  }
  .qr-code {
    position: absolute;
    left: -165px;
    top: 0;
    width: 146px;
    height: 332px;
    background: url("../../static/img/sidebar/qr_code_bg.png") no-repeat center;
    background-size: 100% 100%;
    .qr-code-img {
      width: 64px;
      height: 64px;
      transform: rotate(45deg);
      margin: 102px 0 0 41px;
    }
  }
}
</style>